/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// require jQuery normally
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;


// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');
require('bootstrap-datepicker');
import '../js/sb-admin-2.js';

import '../css/admin/sb-admin-2.scss';
import'../css/app.css';
import'../css/select2.min.css';

require('../../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.en-GB.min.js');
require('../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.css');


require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');

require('@fullcalendar/core/main.css');
require('@fullcalendar/daygrid/main.css');
require('@fullcalendar/timegrid/main.css');
require('@fullcalendar/list/main.css');

const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

const avatarContext = require.context('../avatar', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
avatarContext.keys().forEach(avatarContext);


$(document).ready(function() {
    $('[data-toggle="tooltip"]').tooltip();

});





